<template>
  <div class="profile update-password-container">
    <CategoryTitle :category="category" class="px-3" />

    <UserPassword
      ref="passwordDataForm"
      :userData="userData"
      :isProfileForm="true"
      :showOldPassword="true"
      :showPrivacyFields="false"
      :response="{ status: 0 }"
      @submit="changePassword"
    />
  </div>
</template>

<style lang="scss">
.update-password-container {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .buttons {
      padding: 0 12px;
      label {
        padding-left: 8px;
      }
    }
  }
}
</style>
<script>
import UserService from "~/service/userService";
import category from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle";
import UserPassword from "@/components/profile/form/UserPassword.vue";

export default {
  name: "UpdatePassword",
  components: { CategoryTitle, UserPassword },
  mixins: [category],
  data() {
    return {
      userData: {}
    };
  },
  methods: {
    async changePassword() {
      if (this.$refs.passwordDataForm.$refs.form.validate()) {
        const result = await UserService.updatePassword(
          this.userData.oldPassword,
          this.userData.password
        );
        if (result) {
          this.$refs.passwordDataForm.$refs.form.reset();
        }
      }
    },
    fetchUserData() {
      UserService.getUserDetail().then(user => {
        this.userData = user;
      });
    }
  },
  mounted() {
    this.fetchUserData();
  }
};
</script>

<style lang="scss">
.update-password {
  .v-input__slot {
    background-color: $white !important;
  }
}
</style>
